export var RELATED_POSTS_SETTINGS_PARAMS = {
    postCount: {
        wixParam: 'post-list-postCount',
        appSettingsPath: 'style.numbers.post-list-postCount',
        defaultValue: 3,
        minValue: 1,
        maxValue: 50,
    },
};
export var RELATED_POSTS_DISPLAY_PARAMS = {
    seeAllLinkEnabled: {
        wixParam: 'see-all-link-enabled',
        appSettingsPath: 'style.booleans.see-all-link-enabled',
        defaultValue: true,
    },
    widgetTitleEnabled: {
        wixParam: 'widget-title-enabled',
        appSettingsPath: 'style.booleans.widget-title-enabled',
        defaultValue: true,
    },
};
export var RELATED_POSTS_DISPLAY_PARAMS_MOBILE = {
    seeAllLinkEnabled: {
        wixParam: 'see-all-link-mobile-enabled',
        appSettingsPath: 'style.booleans.see-all-link-mobile-enabled',
        defaultValue: true,
    },
    widgetTitleEnabled: {
        wixParam: 'widget-title-mobile-enabled',
        appSettingsPath: 'style.booleans.widget-title-mobile-enabled',
        defaultValue: true,
    },
};
export var RELATED_POSTS_LAYOUT_PARAMS = {
    widgetBackgroundColor: {
        wixParam: 'widget-backgroundColor',
        appSettingsPath: 'style.colors.widget-backgroundColor',
        defaultValue: 'color-1',
        defaultOpacity: 0,
    },
    sliderLoop: {
        wixParam: 'slider-loop',
        appSettingsPath: 'style.booleans.slider-loop',
        defaultValue: false,
    },
    sliderAutoSlide: {
        wixParam: 'slider-autoSlide',
        appSettingsPath: 'style.booleans.slider-autoSlide',
        defaultValue: false,
    },
    sliderPauseTime: {
        wixParam: 'slider-pauseTime',
        appSettingsPath: 'style.numbers.slider-pauseTime',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'slider-showArrows',
        appSettingsPath: 'style.booleans.slider-showArrows',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'slider-arrowsPosition',
        appSettingsPath: 'style.numbers.slider-arrowsPosition',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'slider-arrowsSize',
        appSettingsPath: 'style.numbers.slider-arrowsSize',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'slider-arrowsColor',
        appSettingsPath: 'style.colors.slider-arrowsColor',
        defaultValue: 'color-5',
    },
};
export var RELATED_POSTS_LAYOUT_PARAMS_MOBILE = {
    widgetBackgroundColor: {
        wixParam: 'widget-mobile-backgroundColor',
        appSettingsPath: 'style.colors.widget-mobile-backgroundColor',
        defaultValue: 'color-1',
        defaultOpacity: 0,
    },
    sliderLoop: {
        wixParam: 'slider-mobile-loop',
        appSettingsPath: 'style.booleans.slider-mobile-loop',
        defaultValue: false,
    },
    sliderAutoSlide: {
        defaultValue: true,
        wixParam: 'slider-mobile-autoSlide',
        appSettingsPath: 'style.booleans.slider-mobile-autoSlide',
    },
    sliderPauseTime: {
        wixParam: 'slider-mobile-pauseTime',
        appSettingsPath: 'style.numbers.slider-mobile-pauseTime',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'slider-mobile-showArrows',
        appSettingsPath: 'style.booleans.slider-mobile-showArrows',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'slider-mobile-arrowsPosition',
        appSettingsPath: 'style.numbers.slider-mobile-arrowsPosition',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'slider-mobile-arrowsSize',
        appSettingsPath: 'style.numbers.slider-mobile-arrowsSize',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'slider-mobile-arrowsColor',
        appSettingsPath: 'style.colors.slider-mobile-arrowsColor',
        defaultValue: 'color-5',
    },
};
export var SEE_ALL_LINK_DESIGN_PARAMS = {
    layoutType: {
        wixParam: 'see-all-link-layoutType',
        appSettingsPath: 'style.numbers.see-all-link-layoutType',
        defaultValue: 0,
        values: {
            text: 0,
            button: 1,
        },
    },
};
export var SEE_ALL_LINK_TEXT_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'see-all-link-text-textFont',
        appSettingsPath: 'style.fonts.see-all-link-text-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'see-all-link-text-textColor',
        appSettingsPath: 'style.colors.see-all-link-text-textColor',
        defaultColor: 'color-8',
    },
    textColorHover: {
        wixParam: 'see-all-link-text-textColorHover',
        appSettingsPath: 'style.colors.see-all-link-text-textColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
    uppercase: {
        wixParam: 'see-all-link-text-uppercase',
        appSettingsPath: 'style.booleans.see-all-link-text-uppercase',
        defaultValue: false,
    },
};
export var SEE_ALL_LINK_BUTTON_DESIGN_PARAMS = {
    verticalPadding: {
        wixParam: 'see-all-link-button-verticalPadding',
        appSettingsPath: 'style.numbers.see-all-link-button-verticalPadding',
        defaultPadding: 6,
        minPadding: 0,
        maxPadding: 100,
    },
    horizontalPadding: {
        wixParam: 'see-all-link-button-horizontalPadding',
        appSettingsPath: 'style.numbers.see-all-link-button-horizontalPadding',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 100,
    },
    textFont: {
        wixParam: 'see-all-link-button-textFont',
        appSettingsPath: 'style.fonts.see-all-link-button-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'see-all-link-button-textColor',
        appSettingsPath: 'style.colors.see-all-link-button-textColor',
        defaultColor: 'color-1',
    },
    uppercase: {
        wixParam: 'see-all-link-button-uppercase',
        appSettingsPath: 'style.booleans.see-all-link-button-uppercase',
        defaultValue: false,
    },
    backgroundColor: {
        wixParam: 'see-all-link-button-backgroundColor',
        appSettingsPath: 'style.colors.see-all-link-button-backgroundColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    cornerRadius: {
        wixParam: 'see-all-link-button-cornerRadius',
        appSettingsPath: 'style.numbers.see-all-link-button-cornerRadius',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
    },
    borderColor: {
        wixParam: 'see-all-link-button-borderColor',
        appSettingsPath: 'style.colors.see-all-link-button-borderColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    borderWidth: {
        wixParam: 'see-all-link-button-borderWidth',
        appSettingsPath: 'style.numbers.see-all-link-button-borderWidth',
        defaultWidth: 0,
        minWidth: 0,
        maxWidth: 10,
    },
    textColorHover: {
        wixParam: 'see-all-link-button-textColorHover',
        appSettingsPath: 'style.colors.see-all-link-button-textColorHover',
        defaultColor: 'color-1',
        defaultOpacity: 1,
    },
    backgroundColorHover: {
        wixParam: 'see-all-link-button-backgroundColorHover',
        appSettingsPath: 'style.colors.see-all-link-button-backgroundColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
    borderColorHover: {
        wixParam: 'see-all-link-button-borderColorHover',
        appSettingsPath: 'style.colors.see-all-link-button-borderColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
};
export var WIDGET_TITLE_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'widget-title-textFont',
        appSettingsPath: 'style.fonts.widget-title-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'widget-title-textColor',
        appSettingsPath: 'style.colors.widget-title-textColor',
        defaultColor: 'color-8',
    },
};
